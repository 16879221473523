import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import onClickOutside from "react-onclickoutside"
import { decode } from "html-entities"

function Nav({ data }) {
  const [nav, showNav] = useState(false)
  const [input, setInput] = useState("")
  const refSearchInput = useRef(null)

  let searchList = data.posty.edges.map((item, index) => item)

  const handleChange = e => {
    e.preventDefault()
    setInput(e.target.value)
  }

  function removeAccentedChars(input) {
    input = input.toLowerCase()

    let output = ""
    for (var i = 0; i < input.length; i++) {
      if (input.charAt(i) === "a") output = output + "[aàáâãäåæ]"
      else if (input.charAt(i) === "c") output = output + "[cç]"
      else if (input.charAt(i) === "e") output = output + "[eèéêëæ]"
      else if (input.charAt(i) === "i") output = output + "[iìíîï]"
      else if (input.charAt(i) === "n") output = output + "[nñ]"
      else if (input.charAt(i) === "o") output = output + "[oòóôõöø]"
      else if (input.charAt(i) === "s") output = output + "[sß]"
      else if (input.charAt(i) === "u") output = output + "[uùúûü]"
      else if (input.charAt(i) === "y") output = output + "[yÿ]"
      else output = output + input.charAt(i)
    }
    return output
  }

  if (input.length > 0) {
    searchList = searchList.filter(i => {
      return i.node.marka.toLowerCase().match(removeAccentedChars(input))
    })
  }

  Nav.handleClickOutside = () => {
    showNav(false)
  }

  // useEffect(() => {
  //   const focusDelay = setTimeout(() => {
  //     refSearchInput.current.focus()
  //   }, 1100)
  //   return () => clearTimeout(focusDelay)
  // }, [nav])

  useEffect(() => {
    setInput("")
  }, [nav])

  useEffect(() => {
    document.addEventListener("keyup", escapeClosesNav)
    return () => document.removeEventListener("keyup", escapeClosesNav)
  }, [])

  function escapeClosesNav(e) {
    if (e.keyCode === 27) {
      showNav(false)
    }
  }

  return (
    <Wrapper>
      <Hamburger nav={nav} onClick={() => showNav(!nav)}>
        <div />
        <div />
        <div />
      </Hamburger>

      <Navigation nav={nav}>
        <h3>Recenzje alfabetycznie</h3>

        <SearchInput>
          <div className="input-wrapper">
            <input
              onChange={handleChange}
              value={input}
              ref={refSearchInput}
              placeholder="&#128269;"
              spellCheck="false"
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => {
                e.target.placeholder = `${decode("&#128269;")}`
              }}
            />
            <span className="input-highlight">{input}</span>
          </div>
        </SearchInput>

        <ul>
          {searchList.length > 0 ? (
            searchList.map((item, index) => {
              return (
                <li key={item.node.id}>
                  <Link to={`/${item.node.slug}`}>
                    &#x203B; {item.node.marka}
                  </Link>
                </li>
              )
            })
          ) : (
            <p>Brak wyników.</p>
          )}
        </ul>
      </Navigation>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  z-index: 1;
`

const SearchInput = styled.div`
  .input-wrapper {
    position: relative;
    width: 70%;
    margin: 40px auto 20px;
  }

  .input-highlight {
    position: absolute;
    left: 0;
    bottom: -8px;
    max-width: 100%;
    height: 0;

    font-size: 1.6rem;
    user-select: none;
    line-height: 2;

    color: transparent;
    overflow: hidden;
    border-top: 3px solid #eeeeee;
  }

  input {
    width: 100%;
    min-width: 100%;
    padding: 0;
    border-radius: 0;
    line-height: 2;
    background-color: transparent;
    color: #eeeeee;

    font-size: 1.6rem;
    border: none;
    outline: none;
    border-bottom: 3px solid #bdbdbd;

    &::placeholder {
      color: #e0e0e0;
    }

    &:focus {
      border-bottom: 3px solid white;
      color: white;

      + .input-highlight {
        border-top: 3px solid #e0d81d;
        border-top: 3px solid #fedc00;
      }
    }
  }
`

const Hamburger = styled.button`
  z-index: 5;

  cursor: pointer;
  border: 0;
  margin: 0;
  background-color: transparent;

  height: 1.5rem;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  outline: none;

  &:hover div:first-child,
  &:hover div:nth-child(2),
  &:hover div:last-child {
    background: #fedc00;
  }

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: #424242;
    border-radius: 2px;
    transform-origin: 1px;
    position: relative;

    outline: none;

    transition: background 0.5s, opacity 0.5s, transform 0.25s;

    :first-child {
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
      background: ${({ nav }) => (nav ? "#fedc00" : "#424242")};
    }

    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
      background: ${({ nav }) => (nav ? "#fedc00" : "#424242")};
    }
  }
`

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;

  border-left: 4px solid #fedc00;
  border-bottom: 4px solid #fedc00;
  border-bottom-left-radius: 16px;
  padding: 0rem 1rem;

  align-items: center;
  justify-content: center;

  display: grid;
  /* grid-template-rows: 120px 1fr 40px; */
  grid-template-rows: 160px auto 1fr 40px;

  text-align: center;

  min-height: 100vh;
  height: auto;
  width: 76%;

  position: absolute;
  top: 0;
  right: 0;

  position: ${({ nav }) => (nav ? "absolute" : "fixed")};

  transition: 0.5s;
  transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};

  /* background-color: rgba(35, 154, 135, 0.45); */
  backdrop-filter: blur(8px); /* not on mozilla */

  background-color: #424242;

  @media (min-width: 500px) {
    width: 60vw;
  }

  @media (min-width: 700px) {
    width: 50vw;
  }

  @media (min-width: 900px) {
    width: 40vw;
  }

  @media (min-width: 1100px) {
    width: 30vw;
  }

  ul {
    list-style-type: none;

    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    height: auto;

    align-self: flex-start;
  }

  li {
    margin: 1rem;
    text-align: left;
  }

  a {
    text-decoration: none;
    color: #e0d81d;
    color: #fedc00;
    font-size: 1.2rem;
    font-weight: bold;
    transition: 0.2s;

    :hover {
      color: #fafafa;
    }
  }

  h3 {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fafafa;
    align-self: flex-end;

    @media (max-width: 410px) {
      font-size: 1.2rem;
    }
  }

  p {
    width: 94%;
    margin: 0 auto;
    color: #fafafa;
  }

  span {
    font-size: 1.2rem;
    display: block;
    margin: 0.5rem auto;
  }
`

const clickOutsideConfig = {
  handleClickOutside: () => Nav.handleClickOutside,
}

export default onClickOutside(Nav, clickOutsideConfig)
