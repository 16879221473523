import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { StaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"

import { TinyButton as ScrollUpButton } from "react-scroll-up-button"

import "./layout.css"

import Logo from "./Logo"
import Nav from "./Nav"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Top>
        <Logo />
        <Manifest>
          <Link to="/manifest">manifest</Link>
        </Manifest>

        <StaticQuery
          query={graphql`
            {
              posty: allContentfulBlog(sort: { fields: marka, order: ASC }) {
                edges {
                  node {
                    slug
                    marka
                    published(formatString: "LL", locale: "pl")
                    id: contentful_id
                  }
                }
              }
            }
          `}
          render={data => {
            return <Nav data={data} />
          }}
        />
      </Top>

      <main>{children}</main>
      <ScrollUpButton
        style={{
          backgroundColor: "transparent",
          fill: "#424242",
          outline: "none",
        }}
      />
    </>
  )
}

const Top = styled.div`
  /* max-width: 980px; */
  max-width: 1180px;

  padding: 1rem 1.5rem;
  margin: 1.5rem auto 1.5rem;

  font-family: "Spartan", sans-serif;

  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-end;

  /**/
  @media (min-width: 1000px) {
    /* width: 90%; */
    max-width: 980px;
  }
  @media (min-width: 1100px) {
    width: 80%;
    padding: 1rem 2.5rem;
  }
  /* @media (min-width: 1200px) {
    width: 76%;
    padding: 1rem 2.5rem;
  } */
  @media (min-width: 1300px) {
    width: 60%;
    padding: 1rem 2.5rem;
  }
`

const Manifest = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 8px;
    bottom: -4px;
    left: 0;
    background-color: #fedc00;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  /**/

  /* @media (max-width: 500px) {
    margin: 1rem auto;
  } */

  /**/
  a {
    color: #424242;

    font-weight: 300;

    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.6rem;
    letter-spacing: -1px;

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }

    :hover {
      font-weight: bold;
    }
  }
`

const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  color: #424242;
}

::selection {
  background: #424242;
  color: #e0d81d;
}

/* p {
  font-size: 1rem;

  @media (min-width: 500px) {
    font-size: 1.1rem;
  }

  @media (min-width: 700px) {
    font-size: 1.2rem;
  }
} */
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
