import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export default class Logo extends Component {
  render() {
    return (
      <LogoWrapper>
        <Link to="/">
          {/* <a href="/"> */}
          <span>rum</span>
          &#x203B;
          <span>blog</span>
          {/* </a> */}
        </Link>
      </LogoWrapper>
    )
  }
}

const LogoWrapper = styled.div`
  display: flex;
  text-transform: uppercase;

  position: relative;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 8px;
    bottom: -4px;
    left: 0;
    background-color: #fedc00;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  a {
    color: #424242;

    text-decoration: none;
    font-size: 1.6rem;
    letter-spacing: -1px;

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }


  :hover span {
    font-weight: bold;

`
